<template>
    <!-- User Profile Personal Basic Info Tab Content View From Account Profile -->
    <div>
        <div class="tabs-content-secondblock padding-bottom">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ml-2 mr-1 mb-3">
                        <div class="row" v-if="ajax_call_in_progress">
                            <div class="col-lg-12 py-5 px-3 mt-3">
                                <div class="flex-container">
                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-lg-12">
                                <div class="infoHeader px-3 pb-4 mt-6">
                                    <div class="create-company-header d-flex align-items-center justify-content-between">
                                        <span class="fs-14 text-white pl-4">Headquarter and Branches</span>
                                        <span class="pointer">
                                            <div>
                                                <button class="btn btn-new-primarys" @click="createNewBranch()">+ Add Branch</button>
                                            </div>
                                            <!-- <i class="text-white" :class="{'icon-chevron-double-down':company_info_expand, 'icon-chevron-double-up':!company_info_expand}" style="font-size: 20px;"></i> -->
                                        </span>
                                    </div>
                                    <div>
                                        <div v-if="branch_data.length && !ajax_call_in_progress" class="row flex-wrap align-items-center">
                                            <div v-for="(branch, index) in branch_data" :key="index+'bran'" class="col-lg-4 col-md-4 col-sm-6 pt-5">
                                                <div class="new-card">
                                                    <!-- :class="{'justify-content-between':branch.is_headquarter, 'justify-content-end':!branch.is_headquarter}" -->
                                                    <!-- v-if="branch.is_headquarter" -->
                                                    <div class="d-flex align-items-center header-colors justify-content-between" :class="{'header-color':branch.is_headquarter}">
                                                        <div class="fs-14 ml-2 d-flex align-items-center fw-600">
                                                            <i v-if="branch.is_headquarter" class="icon icon-star fs-24" style="color: #058125;"></i>
                                                            {{branch.state}}, {{branch.country}}
                                                        </div>
                                                        <div class="position-relative align-items-center">
                                                            <span v-if="branch.is_headquarter" class="pr-1">
                                                                <img width="20px"  height="18px" src="/static/images/star-icon.svg" style="margin-top: -6px !important;cursor: no-drop;"/>
                                                            </span>
                                                            <span v-else class="pr-1">
                                                                <img width="20px" height="18px" class="pointer" @click="assignAsHead(branch.id)" src="/static/images/star-block-icon.svg" style="margin-top: -6px !important;"/>
                                                            </span>
                                                            <!-- <i class="icon icon-star defaultDarkBlue pr-1 fs-20" @click="assignAsHead(branch.id)" style="color: #888888 !important;"></i> -->
                                                            <i v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" @click="goToBranchView(branch.id, index+1)" class="icon icon-pencil defaultDarkBlue pointer fs-20"></i>
                                                            <i v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" @click="showDeleteConfirm(branch.id)" class="icon icon-delete pointer fs-20 pl-1" :class="{'delete-color':branch.is_headquarter}" style="color: #eb4848;"></i>
                                                            <!-- <el-dropdown v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" trigger="click">
                                                                <span class="el-dropdown-link">
                                                                    <i class="icon icon-dots-vertical defaultDarkBlue pointer"></i>
                                                                </span>
                                                                <el-dropdown-menu class="comapny_branch p-0" slot="dropdown">
                                                                    <el-dropdown-item class="text-secondary">
                                                                        <span @click="assignAsHead(branch.id)">Assign as Headquarter</span>
                                                                    </el-dropdown-item>
                                                                </el-dropdown-menu>
                                                            </el-dropdown> -->
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="pointer">
                                                        <!-- <div class="d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <h1 class="fs-14 my-2 ml-2" style="color: #3f3f3f;border-bottom: 0.5px solid #707070;">Branch {{index+1}} </h1>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div> -->
                                                        <!-- <span style="width:60px;left:0 !important; margin-top:0px;border-bottom: 0.5px solid #128807;"></span> -->
                                                        <div class="pl-2 pr-3 mt-2">
                                                            <div class="text-secondary d-flex align-items-center fs-12 fw-500">
                                                                <span class="mr-2" style="display: inline-block;"><i class="icon icon-phone fs-18" style="color: #19181c;"></i></span>
                                                                <span v-if="branch.phone_number" class="defaultblack">Phone &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {{branch.phone_number.phone_number || "-"}}
                                                                </span>
                                                                <span v-else>
                                                                    -
                                                                </span>
                                                            </div>
                                                            <div class="text-secondary d-flex align-items-center fs-12 fw-500" style="color: rgba(0,0,0,0.54);">
                                                                <span class="mr-2" style="display: inline-block;"><i class="icon icon-email fs-18" style="color: #19181c;"></i></span>
                                                                <span class="defaultblack" style="display: inline-block;">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                   <span v-if="branch.email" class="defaultblack" style="display: inline-block;text-decoration: underline;"> {{branch.email}}</span>
                                                                   <span v-else class="defaultblack" style="display: inline-block;"> {{"-"}}</span>
                                                                <!-- <i class="icon icon-email fs-18" style="color: #19181c;"></i>Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <span>{{branch.email || "-"}}</span> -->
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="d-flex align-items-center pl-2 pr-3 mt-2">
                                                            <h2 class="fs-12 text-secondary fw-500 text-break pr-2">Branch Code</h2>
                                                            <h2 class="fs-12 fw-500 text-data pl-2">{{branch.code || "-"}}</h2>
                                                        </div>
                                                        <div class="d-flex align-items-center pl-2 pr-3">
                                                            <h2 class="fs-12 text-secondary fw-500 text-break pr-2">Employees</h2>
                                                            <h2 class="fs-12 fw-500 text-data pl-5">{{branch.no_of_employees || "-"}}</h2>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="row flex-wrap align-items-center">
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <p class="text-dark fs-14 text-center">Branches Not Found</p>
                                            </div>
                                        </div>
                                        <!-- <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="text-right mt-15">
                                            <button class="btn btn-new-primary" @click="showCreateBranch">+ Add Branch</button>
                                        </div> -->
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!ajax_call_in_progress && branch_data.length > 0" class="d-flex justify-content-end align-items-center bg-white py-2 px-4 pb-10" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                <!-- <multiselect class="diginew-multiselect"
                    :options="pageLimit" :close-on-select="true" v-model.number="limit"
                    open-direction="top" :show-labels="false" placeholder=""
                    style="width: 4.0em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" style="margin-left: -20px;"> -->
                <span class="page-info pr-2 pl-6 fw-500" style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                <button @click="firstPage()"  :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                    <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                        <i class="icon-chevron-double-left"></i>
                    </el-tooltip>    
                </button>
                <button @click="previousPage()"  :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                    <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                        <i class="icon icon-chevron-left"></i>
                    </el-tooltip>    
                </button>
                <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} - {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                <button @click="nextPage()" :class="{'not-allowed' : temp_total == total }" :disabled="temp_total == total" class="pagination-list mt-1 mr-2 pointer">
                    <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                        <i class="icon icon-chevron-right"></i>
                    </el-tooltip>    
                </button>
                <button @click="lastPage()" :class="{'not-allowed' : temp_total == total}" class="mt-1 pointer pagination-list" :disabled ="temp_total == total">
                    <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                        <i class="icon-chevron-double-right"></i>
                    </el-tooltip>    
                </button>
            </div>
        </div>
        <branch-delete-popup v-if="delete_confirm" @hideConfirm="hideConfirm" @deleteConfirm="deleteConfirm" modal_name="delete_confirm" ></branch-delete-popup>
        <create-branch v-if="create_branch" @hideCreateBranch="hideCreateBranch" modal_name="create-branch"></create-branch>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import moment from "moment";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    const CreateBranch = () => import('./Branch/CreateBranch');
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import { SweetModal } from 'sweet-modal-vue';
    import companies from '../mixins/companies.js';
    import BranchDeletePopup from './BranchDeletePopup.vue';
    export default {
        props: [],
        components:{
            CreateBranch,
            HalfCircleSpinner,
            SweetModal,
            BranchDeletePopup
        },
        data() {
            return {
                create_branch: false,
                branch_data: [],
                ajax_call_in_progress: false,
                warning_msg: '',
                success_msg: '',
                delete_confirm: false,
                branch_delete: '',
                skip:0,
                limit: 10,
                total: 0,
                skip_temp:0,
                temp_total:0,
            };
        },
        mixins: [companies],
        methods: {
            previousPage() {
                this.temp_total = 0
                this.skip_temp = 0
                if (this.skip == 0) {
                    return;
                }
                this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
                this.getListViewData();
            },
            nextPage() {
                this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp;
                this.getListViewData();
            },
            firstPage(){
                this.temp_total = 0
                this.skip = 0
                this.limit = this.limit
                this.branch_data = [] 
                this.getListViewData();
            },
            lastPage() {
                this.skip_temp = 0
                this.skip = 0
                this.temp_total = this.total
                let no_of_pages = Math.ceil(this.total/this.limit)
                this.skip_temp = (no_of_pages * this.limit) - this.limit
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp
                this.getListViewData();
            },
            hideConfirm() {
                this.delete_confirm = false;
            },
            showDeleteConfirm(id) {
                this.branch_delete = id
                this.delete_confirm = true;
                setTimeout(() => {
                    this.$modal.show('delete_confirm');
                }, 500);
            },
            deleteConfirm() {
                // this.DeleteAll();
                if(this.branch_delete === undefined || this.branch_delete === null || this.branch_delete === '') {
                    this.delete_confirm = false;
                } else {
                    this.deleteBranch(this.branch_delete);
                }
            },
            moment(date) {
                return moment(date);
            },
            hideCreateBranch() {
                this.create_branch = false;
                this.getListViewData();
            },
            createNewBranch() {
                this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {branch: 'add_branch'} })
                // this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {createbranch} })
            },
            showCreateBranch() {
                this.create_branch = true;
                setTimeout(() => {
                    this.$modal.show('create-branch');
                }, 500);
            },
            async getListViewData() {
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                        company_id: this.$route.params.company_id
                    };
                    let response = await this.getListBranch(params.skip, params.limit, params.company_id);
                    if(response.status_id == 1) {
                        this.branch_data = response.response
                        this.total = response.count;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    console.log(err);
                    // localStorage.setItem("company_branch_domain_name");
                }
            },
            async getCompanyInfo() {
                try {
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if(response.status_id == 1) {
                        localStorage.setItem('company_branch_domain_name', response.response.domain);
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async assignAsHead(id) {
                try {
                    let params = {
                        branch_id: id,
                        company_id: this.$route.params.company_id
                    }
                    let response = await this.assignAsHeadQuarter(params);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getListViewData();
                        }, 2000);
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            async deleteBranch(id) {
                // this.$swal({
                //     title: 'Alert',
                //     text: "Are you sure want to delete ?",
                //     type: 'warning',
                //     icon: 'warning',
                //     showCloseButton: true,
                //     showCancelButton: true,
                //     confirmButtonText:'YES',
                //     cancelButtonText:'NO'
                // }).then(async (result) => {
                //     if (result.value) {
                        try {
                            let response = await this.deleteBranchById(id);
                            console.log(response);
                            if(response.status_id == 1) {
                                this.success_msg = response.message
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                    this.getListViewData();
                                }, 2000);
                            }
                        }
                        catch(err) {
                            // console.log(err);
                            this.warning_msg = err;
                            this.$refs.warning_modal.open();
                        }
                //     }
                // })
            },
            goToBranchView(id, index) {
                this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {branch: id, branch_number: index} })
            }
        },
        props: [],
        mounted() {
            this.getListViewData();
            this.getCompanyInfo();
        },
        created() {
        },
        watch: {
            // "edit_user_data.gender": function(newVal,oldVal) {
            //     if (newVal == 'Custom') {
            //         this.custom_gender_active = true;
            //     } else {
            //         this.custom_gender_active = false;
            //     }
            // },
        },
        computed: {
            getSkipCount(){
                if(this.total == 0) {
                    return 0
                } else {
                    let skip_count = this.skip>this.total?this.total:this.skip+1;
                    return skip_count;
                }
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
    };
</script>
<style scoped>
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }
    .new-card {
        /* padding: 3.5px 5.5px 12.8px 11.5px; */
        padding:15px;
        border-radius: 2.5px;
        box-shadow: 0 0 10px 0 #e5e5e5;
        background-color: var(--white);
    }
    .headquarter-text {
        padding: 3px 4.3px 3px 4.5px;
        border-radius: 4px;
        background-color: #128807;
    }
    @media only screen and (max-width: 768px){
        .padding-bottom{
            margin-bottom: 100px !important;
        }
    }
    .btn-new-primarys{
        background-color: #fff;
        color: #058125;
        border-radius: 6px;
        padding: 0px 10px 0px 10px;
        font-weight: 600;
        font-size: 14px !important;
    }
    .btn {
        height: 2.6rem;
        line-height: 2.6rem;
    }
    .text-secondary{
        color: #19181c !important;
    }
    .header-color{
        color: #058125;
    }
    .header-colors{
        color: #00448b;
    }
    .delete-color{
        color: #888888 !important;
        cursor: no-drop !important;
    }
    .not-allowed{
        cursor: no-drop !important;
    }
</style>